import { ChargerStatus, DeviceStatus } from '@hiven-energy/hiven-client';
import { Typography } from '@hiven-energy/hiven-ui';
import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { statusToMessage } from 'src/containers/device-status/constants';
import { colors } from 'src/theme';
import { formatRelativeTime } from 'src/utils/time';
import { convertEnergyUnits, EnergyUnit, formatEnergy } from 'src/utils/units';

import * as styled from './styles';

const ENERGY_UNIT = EnergyUnit.KWH;

interface Props {
  telemetry: ChargerStatus | undefined;
  deviceStatus: DeviceStatus | undefined;
}

const Telemetry: FC<Props> = ({ telemetry, deviceStatus = DeviceStatus.INITIALIZATION }) => {
  const intl = useIntl();

  const { measurement, heartbeatTimestamp } = telemetry ?? {};

  const energyTransfer = measurement?.value && convertEnergyUnits(measurement.value, EnergyUnit.WH, ENERGY_UNIT);

  return (
    <styled.Container>
      <styled.Header variant="h2">{intl.formatMessage({ id: 'ChargerDashboard.telemetry.title' })}</styled.Header>
      <styled.Content>
        <styled.ContentBox>
          <Typography variant="h2" color={colors.dodgerBlue}>
            {energyTransfer ? formatEnergy(energyTransfer, ENERGY_UNIT) : '-'}
          </Typography>
          <styled.Heartbeat>
            <Typography color={colors.fogGrey}>
              <FormattedMessage id="ChargerDashboard.telemetry.heartbeat" />
            </Typography>
            <Typography color={colors.fogGrey}>
              {heartbeatTimestamp ? formatRelativeTime(heartbeatTimestamp, intl) : '~'}
            </Typography>
          </styled.Heartbeat>
        </styled.ContentBox>
        <styled.Separator />
        <styled.ContentBox>
          <Typography variant="h2" color={colors.dodgerBlue}>
            <FormattedMessage {...statusToMessage[deviceStatus]} />
          </Typography>
        </styled.ContentBox>
      </styled.Content>
    </styled.Container>
  );
};

export default React.memo(Telemetry);
