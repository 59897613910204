import {
  ChargingSchedule,
  DeviceStatus,
  Vehicle,
  VehicleChargingBehavior,
  VehiclePreferences,
  VehicleStatus,
} from '@hiven-energy/hiven-client';
import { IntlShape } from 'react-intl';

import { Property } from 'src/containers/device-overview/Property/Property';
import { statusToEvMessage } from 'src/containers/device-status/constants';
import { formatRelativeTime } from 'src/utils/time';
import { formatKilometers, formatPercents } from 'src/utils/units';

import { arePreferencesSet } from '../../utils';

export const prepareDetails = (vehicle: Vehicle, status: VehicleStatus | undefined, intl: IntlShape): Property[] => [
  {
    name: intl.formatMessage({ id: 'common.deviceOverview.deviceDetails.deviceModel' }),
    value: vehicle.attributes.model,
  },
  {
    name: intl.formatMessage({ id: 'common.deviceOverview.deviceDetails.deviceId' }),
    value: vehicle.id,
    copyable: true,
  },
  {
    name: intl.formatMessage({ id: 'common.deviceOverview.deviceDetails.firstConnection' }),
    value: formatRelativeTime(vehicle.createdAt, intl),
  },
  {
    name: intl.formatMessage({ id: 'common.deviceOverview.deviceDetails.lastConnection' }),
    value: status?.timestamp && formatRelativeTime(status.timestamp, intl),
  },
];

export const prepareDiagnostics = (
  vehicle: Vehicle,
  deviceStatus: DeviceStatus,
  status: VehicleStatus | undefined,
  preferences: VehiclePreferences | undefined,
  schedule: ChargingSchedule | undefined,
  intl: IntlShape,
): Property[] => [
  {
    name: intl.formatMessage({ id: 'common.deviceOverview.diagnostics.smartCharge' }),
    value: preferences?.chargingBehavior
      ? preferences.chargingBehavior === VehicleChargingBehavior.SMART_CHARGE_OFF
        ? intl.formatMessage({ id: 'common.disabled' })
        : intl.formatMessage({ id: 'common.enabled' })
      : undefined,
  },
  {
    name: intl.formatMessage({ id: 'common.deviceOverview.diagnostics.chargingStatus' }),
    value: status?.chargingStatus && intl.formatMessage(statusToEvMessage[deviceStatus]),
  },
  {
    name: intl.formatMessage({ id: 'common.deviceOverview.diagnostics.scheduleAvailable' }),
    value: !!schedule,
    isApplicable: !vehicle.associatedToCharger,
  },
  {
    name: intl.formatMessage({ id: 'common.deviceOverview.diagnostics.preferencesSet' }),
    value: !!preferences && arePreferencesSet(preferences),
  },
  {
    name: intl.formatMessage({ id: 'vehicle.deviceOverview.diagnostics.currentChargeLevel' }),
    value: status?.soc && formatPercents(status?.soc),
  },
  {
    name: intl.formatMessage({ id: 'vehicle.deviceOverview.diagnostics.range' }),
    value: status?.range && formatKilometers(status?.range),
  },
  {
    name: intl.formatMessage({ id: 'common.deviceOverview.diagnostics.effectiveReadyTime' }),
    value: schedule && formatRelativeTime(schedule.readyTime, intl),
    isApplicable: !vehicle.associatedToCharger,
  },
  {
    name: intl.formatMessage({ id: 'vehicle.deviceOverview.diagnostics.locationSupported' }),
    value: vehicle.settings.locationSupported,
  },
];
