import { ChargerStatus, DeviceStatus, VehicleStatus } from '@hiven-energy/hiven-client';
import { Progress, ProgressLinearBarType } from '@hiven-energy/hiven-ui';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { TogglePanel } from 'src/components/TogglePanel/TogglePanel';
import { ChargeLevels } from 'src/containers/charge-levels/types';
import { DeviceList } from 'src/containers/DeviceList/DeviceList';
import { useScreenDimensions } from 'src/hooks/useScreenDimensions';
import { convertEnergyUnits, EnergyUnit, roundEnergy } from 'src/utils/units';

import { useAppTheme } from '../../../app-theme';
import {
  defaultProgressbarColor,
  statusToEvMessage,
  statusToIcon,
  statusToMessage,
  statusToMessageColor,
  statusToProgressColor,
  statusToProgressVariant,
  statusToStateOfChargeColor,
  typeToTitle,
} from '../constants';

import * as styled from './styles';

const ENERGY_UNIT = EnergyUnit.KWH;

type ProgressBarColors = Partial<Record<ProgressLinearBarType, string>>;

interface ChargerProps {
  type: 'charger';
  chargerStatus: ChargerStatus | undefined;
}

interface VehicleProps {
  type: 'vehicle';
  vehicleStatus: VehicleStatus | undefined;
}

interface PairedProps {
  type: 'paired';
  pairedChargerName: string | undefined;
  vehicleStatus: VehicleStatus | undefined;
}

type DeviceProps = ChargerProps | VehicleProps | PairedProps;

interface Props {
  name: string | undefined;
  lastHeartbeat?: string;
  deviceId: string | undefined;
  connectionPending: boolean;
  isOffline?: boolean;
  preferencesSet: boolean | undefined;
  deviceStatus: DeviceStatus | undefined;
  chargeLevels: ChargeLevels;
  showDeviceList: boolean;
  onToggleDeviceList: VoidFunction;
}

const Status: FC<Props & DeviceProps> = props => {
  const {
    name,
    chargeLevels,
    preferencesSet,
    deviceId,
    connectionPending,
    isOffline,
    deviceStatus = DeviceStatus.INITIALIZATION,
    showDeviceList,
    onToggleDeviceList,
    lastHeartbeat,
  } = props;

  const insets = useSafeAreaInsets();
  const [_, screenHeight] = useScreenDimensions();

  const appTheme = useAppTheme();

  const [showContent, setShowContent] = useState(false);

  const socColor = statusToStateOfChargeColor[deviceStatus];
  const statusMessage = props.type === 'vehicle' ? statusToEvMessage[deviceStatus] : statusToMessage[deviceStatus];
  const StatusIcon = statusToIcon[deviceStatus];
  const statusColor = statusToMessageColor[deviceStatus];

  const title = name || '-';

  return (
    <styled.Container maxHeight={screenHeight}>
      <styled.StateContent insets={insets} backgroundColor={appTheme.main.color}>
        <styled.Label>
          {props.type === 'paired' ? (
            <FormattedMessage id="ChargerDashboard.status.pairedWith" values={{ name: props.pairedChargerName }} />
          ) : (
            <FormattedMessage {...typeToTitle[props.type]} />
          )}
        </styled.Label>
        <TogglePanel
          timestamp={lastHeartbeat}
          isActive={!!deviceId}
          title={title}
          toggleContent={deviceId && <DeviceList deviceId={deviceId} onPress={onToggleDeviceList} />}
          accessibilityLabel={title}
          analyticsPlace="ChargerDashboard.status"
          open={showDeviceList}
          onToggle={onToggleDeviceList}
          onStateChange={setShowContent}
        />
        {showContent && (
          <styled.Content>
            {connectionPending || isOffline ? (
              <styled.Status>
                {connectionPending && <FormattedMessage id="ChargerDashboard.status.connectionPending" />}
                {isOffline && <FormattedMessage id="ChargerDashboard.status.offline" />}
              </styled.Status>
            ) : (
              <>
                {props.type === 'charger' && props.chargerStatus?.measurement && (
                  <EnergyTransfer socColor={socColor} measurement={props.chargerStatus.measurement} />
                )}
                {props.type !== 'charger' && props.vehicleStatus && (
                  <ChargeLevel socColor={socColor} soc={props.vehicleStatus.soc} />
                )}
                <styled.StatusRow>
                  <styled.IconLabelWrapper>
                    <StatusIcon size={20} color={statusColor} />
                    <styled.StatusLabel lineHeight="tight" color={statusColor}>
                      <FormattedMessage {...statusMessage} />
                    </styled.StatusLabel>
                  </styled.IconLabelWrapper>
                  {preferencesSet && props.type !== 'charger' && <PercentageStopAt chargeLevels={chargeLevels} />}
                  {preferencesSet && props.type === 'charger' && <UnitStopAt chargeLevels={chargeLevels} />}
                </styled.StatusRow>
                {props.type === 'charger' && props.chargerStatus && (
                  <ChargerProgress
                    deviceStatus={deviceStatus}
                    measurement={props.chargerStatus.measurement}
                    chargeLevels={chargeLevels}
                  />
                )}
                {props.type !== 'charger' && props.vehicleStatus && (
                  <StatusProgress
                    deviceStatus={deviceStatus}
                    soc={props.vehicleStatus.soc}
                    chargeLevels={chargeLevels}
                  />
                )}
              </>
            )}
          </styled.Content>
        )}
      </styled.StateContent>
      <styled.RoundedBottom backgroundColor={appTheme.main.color} />
    </styled.Container>
  );
};

const ChargerProgress: FC<{
  deviceStatus: DeviceStatus;
  chargeLevels: ChargeLevels;
  measurement: ChargerStatus['measurement'];
}> = ({ deviceStatus, measurement, chargeLevels }) => {
  const progressVariant = statusToProgressVariant[deviceStatus];
  const energyTransfer =
    (measurement && convertEnergyUnits(measurement.value, EnergyUnit.WH, ENERGY_UNIT)) || undefined;
  const progressBarColors = useMemo<ProgressBarColors>(
    () => statusToProgressColor[deviceStatus] || defaultProgressbarColor,
    [deviceStatus],
  );

  return (
    <Progress.Linear
      variant={progressVariant}
      value={energyTransfer}
      maxValue={chargeLevels.maximum}
      barColors={progressBarColors}
    />
  );
};

const StatusProgress: FC<{
  deviceStatus: DeviceStatus;
  chargeLevels: ChargeLevels;
  soc: number;
}> = ({ deviceStatus, chargeLevels, soc }) => {
  const progressVariant = statusToProgressVariant[deviceStatus];
  const progressBarColors = useMemo<ProgressBarColors>(
    () => statusToProgressColor[deviceStatus] || defaultProgressbarColor,
    [deviceStatus],
  );

  return (
    <Progress.Step
      variant={progressVariant}
      value={soc}
      targetValue={chargeLevels.maximum}
      barColors={progressBarColors}
      animationDuration={150}
    />
  );
};

const PercentageStopAt: FC<{
  chargeLevels: ChargeLevels;
}> = ({ chargeLevels }) => {
  const formatPercents = useFormatPercents();
  return (
    <styled.TargetLabel fontSize={14}>
      <FormattedMessage id="VehicleDashboard.status.stopAt" values={{ value: formatPercents(chargeLevels.maximum) }} />
    </styled.TargetLabel>
  );
};

const UnitStopAt: FC<{
  chargeLevels: ChargeLevels;
}> = ({ chargeLevels }) => {
  return (
    <styled.TargetLabel fontSize={14}>
      <FormattedMessage
        id="ChargerDashboard.status.stopAt"
        values={{ value: chargeLevels.maximum, unit: ENERGY_UNIT }}
      />
    </styled.TargetLabel>
  );
};

const ChargeLevel: FC<{ socColor: string; soc: number }> = ({ socColor, soc }) => {
  const formatPercents = useFormatPercents();
  return (
    <styled.StateOfCharge>
      <styled.StateOfChargeValue fontSize={50} fontWeight="bold" color={socColor}>
        {formatPercents(soc)}
      </styled.StateOfChargeValue>
    </styled.StateOfCharge>
  );
};

const EnergyTransfer: FC<{
  measurement: Exclude<ChargerStatus['measurement'], null>;
  socColor: string;
}> = ({ measurement, socColor }) => {
  const energyTransfer = convertEnergyUnits(measurement.value, EnergyUnit.WH, ENERGY_UNIT);

  return (
    <styled.StateOfCharge>
      <styled.StateOfChargeValue fontSize={50} fontWeight="bold" color={socColor}>
        {roundEnergy(energyTransfer)}
      </styled.StateOfChargeValue>
      <styled.Unit fontSize={32} color={socColor}>
        {ENERGY_UNIT}
      </styled.Unit>
    </styled.StateOfCharge>
  );
};

const useFormatPercents = () => {
  const intl = useIntl();

  return useCallback((value: number) => intl.formatNumber(value / 100, { style: 'percent' }), [intl]);
};

export default React.memo(Status);
