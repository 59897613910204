import { Charger as ChargerDevice, DeviceStatus } from '@hiven-energy/hiven-client';
import { PlugIcon } from '@hiven-energy/hiven-ui';
import { useNavigation } from '@react-navigation/native';
import React, { FC } from 'react';
import { InteractionManager, Platform } from 'react-native';

import { DEVICE_STATUS_REFRESH_MILLISECONDS, statusToChargerOnline } from 'src/containers/device-status/constants';
import { RouteId } from 'src/nav/types';
import { useDeviceStatus } from 'src/queries/sdk';

import DeviceItem from '../DeviceItem';

interface Props {
  device: ChargerDevice;
  selectedDeviceId: string;
  onPress?: VoidFunction;
}

export const Charger: FC<Props> = ({ device, selectedDeviceId, onPress }) => {
  const {
    id,
    attributes: { name },
  } = device;

  const navigation = useNavigation();
  const { data: deviceStatus = DeviceStatus.INITIALIZATION } = useDeviceStatus(id, {
    refetchInterval: DEVICE_STATUS_REFRESH_MILLISECONDS,
  });

  const isOnline = statusToChargerOnline[deviceStatus];

  const handlePress = () => {
    if (onPress) onPress();
    if (Platform.OS === 'web') {
      navigation.push(RouteId.ChargerDashboard, { deviceId: id });
    } else {
      const removeListener = navigation.addListener('state', () => {
        InteractionManager.runAfterInteractions(() => {
          removeListener();
          navigation.push(RouteId.ChargerDashboard, { deviceId: id });
        });
      });
    }
  };

  return (
    <DeviceItem
      name={name}
      onPress={handlePress}
      selected={id === selectedDeviceId}
      icon={PlugIcon}
      online={isOnline}
    />
  );
};
