import { DeviceStatus } from '@hiven-energy/hiven-client';
import {
  BatteryAlertIcon,
  BatteryChargingIcon,
  BatteryFullIcon,
  ClockIcon,
  CrossIcon,
  IconProps,
  PlugIcon,
  ProgressLinearBarType,
  ProgressVariant,
  RecurringIcon,
} from '@hiven-energy/hiven-ui';
import { FC } from 'react';
import { defineMessages } from 'react-intl';

import { MessageDescriptor } from 'src/i18n';
import { colors } from 'src/theme';

export const typeToTitle = defineMessages<'charger' | 'vehicle', MessageDescriptor>({
  charger: { id: 'device.status.charger.label' },
  vehicle: { id: 'device.status.vehicle.label' },
});

export const statusToMessage = defineMessages<DeviceStatus, MessageDescriptor>({
  [DeviceStatus.SMART_CHARGING]: { id: 'device.status.smartCharging' },
  [DeviceStatus.IMMEDIATE_CHARGING]: { id: 'device.status.immediateCharging' },
  [DeviceStatus.CABLE_PLUGGED]: { id: 'device.status.cablePlugged' },
  [DeviceStatus.CABLE_UNPLUGGED]: { id: 'device.status.cableUnplugged' },
  [DeviceStatus.CHARGING_CANCELLED]: { id: 'device.status.chargingCancelled' },
  [DeviceStatus.SMART_CHARGING_COMPLETED]: { id: 'device.status.smartChargingCompleted' },
  [DeviceStatus.WAITING_FOR_SCHEDULED_CHARGING]: { id: 'device.status.waitingForScheduledCharging' },
  [DeviceStatus.CALCULATING_SCHEDULE]: { id: 'device.status.calculatingSchedule' },
  [DeviceStatus.ERROR]: { id: 'device.status.error' },
  [DeviceStatus.SMART_CHARGING_DISABLED]: { id: 'device.status.smartChargingDisabled' },
  [DeviceStatus.UNKNOWN]: { id: 'device.status.unknown' },
  [DeviceStatus.INITIALIZATION]: { id: 'device.status.initialization' },
  [DeviceStatus.NOT_REGISTERED]: { id: 'device.status.notRegistered' },
  [DeviceStatus.WAITING_FOR_CONNECTOR_STATE]: { id: 'device.status.waitingForConnectorState' },
  [DeviceStatus.OFFLINE]: { id: 'device.status.offline' },
  [DeviceStatus.UNABLE_TO_CHARGE]: { id: 'device.status.unableToCharge' },
});

export const statusToEvMessage = {
  ...statusToMessage,
  ...defineMessages<DeviceStatus.CABLE_UNPLUGGED, MessageDescriptor>({
    [DeviceStatus.CABLE_UNPLUGGED]: { id: 'vehicle.status.cableUnplugged' },
  }),
};

export const statusToProgressVariant: Record<DeviceStatus, ProgressVariant> = {
  [DeviceStatus.SMART_CHARGING]: ProgressVariant.GROWING_VALUE,
  [DeviceStatus.IMMEDIATE_CHARGING]: ProgressVariant.GROWING_VALUE,
  [DeviceStatus.CABLE_PLUGGED]: ProgressVariant.STANDARD,
  [DeviceStatus.CABLE_UNPLUGGED]: ProgressVariant.STANDARD,
  [DeviceStatus.CHARGING_CANCELLED]: ProgressVariant.STANDARD,
  [DeviceStatus.SMART_CHARGING_COMPLETED]: ProgressVariant.STANDARD,
  [DeviceStatus.WAITING_FOR_SCHEDULED_CHARGING]: ProgressVariant.STANDARD,
  [DeviceStatus.CALCULATING_SCHEDULE]: ProgressVariant.STANDARD,
  [DeviceStatus.ERROR]: ProgressVariant.STANDARD,
  [DeviceStatus.SMART_CHARGING_DISABLED]: ProgressVariant.STANDARD,
  [DeviceStatus.UNKNOWN]: ProgressVariant.STANDARD,
  [DeviceStatus.INITIALIZATION]: ProgressVariant.STANDARD,
  [DeviceStatus.NOT_REGISTERED]: ProgressVariant.STANDARD,
  [DeviceStatus.WAITING_FOR_CONNECTOR_STATE]: ProgressVariant.STANDARD,
  [DeviceStatus.OFFLINE]: ProgressVariant.STANDARD,
  [DeviceStatus.UNABLE_TO_CHARGE]: ProgressVariant.STANDARD,
};

export const statusToIcon: Record<DeviceStatus, FC<IconProps>> = {
  [DeviceStatus.SMART_CHARGING]: BatteryChargingIcon,
  [DeviceStatus.IMMEDIATE_CHARGING]: BatteryChargingIcon,
  [DeviceStatus.CABLE_PLUGGED]: PlugIcon,
  [DeviceStatus.CABLE_UNPLUGGED]: PlugIcon,
  [DeviceStatus.CHARGING_CANCELLED]: CrossIcon,
  [DeviceStatus.SMART_CHARGING_COMPLETED]: BatteryFullIcon,
  [DeviceStatus.WAITING_FOR_SCHEDULED_CHARGING]: ClockIcon,
  [DeviceStatus.CALCULATING_SCHEDULE]: RecurringIcon,
  [DeviceStatus.ERROR]: BatteryAlertIcon,
  [DeviceStatus.SMART_CHARGING_DISABLED]: CrossIcon,
  [DeviceStatus.UNKNOWN]: PlugIcon,
  [DeviceStatus.INITIALIZATION]: ClockIcon,
  [DeviceStatus.NOT_REGISTERED]: PlugIcon,
  [DeviceStatus.WAITING_FOR_CONNECTOR_STATE]: PlugIcon,
  [DeviceStatus.OFFLINE]: PlugIcon,
  [DeviceStatus.UNABLE_TO_CHARGE]: BatteryAlertIcon,
};

export const statusToStateOfChargeColor: Record<DeviceStatus, string> = {
  [DeviceStatus.CABLE_PLUGGED]: colors.pidgeonGray,
  [DeviceStatus.SMART_CHARGING]: colors.sunshineYellow,
  [DeviceStatus.IMMEDIATE_CHARGING]: colors.raspberryPink,
  [DeviceStatus.CABLE_UNPLUGGED]: colors.pidgeonGray,
  [DeviceStatus.CHARGING_CANCELLED]: colors.pidgeonGray,
  [DeviceStatus.SMART_CHARGING_COMPLETED]: colors.sunshineYellow,
  [DeviceStatus.WAITING_FOR_SCHEDULED_CHARGING]: colors.white,
  [DeviceStatus.CALCULATING_SCHEDULE]: colors.pidgeonGray,
  [DeviceStatus.ERROR]: colors.pidgeonGray,
  [DeviceStatus.SMART_CHARGING_DISABLED]: colors.pidgeonGray,
  [DeviceStatus.UNKNOWN]: colors.pidgeonGray,
  [DeviceStatus.INITIALIZATION]: colors.pidgeonGray,
  [DeviceStatus.NOT_REGISTERED]: colors.pidgeonGray,
  [DeviceStatus.WAITING_FOR_CONNECTOR_STATE]: colors.pidgeonGray,
  [DeviceStatus.OFFLINE]: colors.pidgeonGray,
  [DeviceStatus.UNABLE_TO_CHARGE]: colors.pidgeonGray,
};

export const statusToMessageColor: Record<DeviceStatus, string> = {
  [DeviceStatus.ERROR]: colors.raspberryPink,
  [DeviceStatus.UNKNOWN]: colors.white,
  [DeviceStatus.CABLE_PLUGGED]: colors.white,
  [DeviceStatus.SMART_CHARGING]: colors.white,
  [DeviceStatus.IMMEDIATE_CHARGING]: colors.white,
  [DeviceStatus.CABLE_UNPLUGGED]: colors.white,
  [DeviceStatus.CHARGING_CANCELLED]: colors.white,
  [DeviceStatus.SMART_CHARGING_COMPLETED]: colors.white,
  [DeviceStatus.WAITING_FOR_SCHEDULED_CHARGING]: colors.white,
  [DeviceStatus.CALCULATING_SCHEDULE]: colors.white,
  [DeviceStatus.SMART_CHARGING_DISABLED]: colors.white,
  [DeviceStatus.INITIALIZATION]: colors.white,
  [DeviceStatus.NOT_REGISTERED]: colors.white,
  [DeviceStatus.WAITING_FOR_CONNECTOR_STATE]: colors.white,
  [DeviceStatus.OFFLINE]: colors.white,
  [DeviceStatus.UNABLE_TO_CHARGE]: colors.raspberryPink,
};

export const statusToProgressColor: Partial<Record<DeviceStatus, Partial<Record<ProgressLinearBarType, string>>>> = {
  [DeviceStatus.SMART_CHARGING]: {
    [ProgressLinearBarType.VALUE]: colors.sunshineYellow,
    [ProgressLinearBarType.FIRST_ANIMATION]: colors.beachYellow,
    [ProgressLinearBarType.SECOND_ANIMATION]: colors.morningYellow,
  },
  [DeviceStatus.SMART_CHARGING_COMPLETED]: {
    [ProgressLinearBarType.VALUE]: colors.sunshineYellow,
  },
  [DeviceStatus.IMMEDIATE_CHARGING]: {
    [ProgressLinearBarType.VALUE]: colors.raspberryPink,
    [ProgressLinearBarType.FIRST_ANIMATION]: colors.roseRed,
    [ProgressLinearBarType.SECOND_ANIMATION]: colors.roseRed,
  },
};

export const statusToChargingState: Record<DeviceStatus, boolean> = {
  [DeviceStatus.ERROR]: false,
  [DeviceStatus.UNKNOWN]: false,
  [DeviceStatus.CABLE_PLUGGED]: false,
  [DeviceStatus.SMART_CHARGING]: true,
  [DeviceStatus.IMMEDIATE_CHARGING]: true,
  [DeviceStatus.CABLE_UNPLUGGED]: false,
  [DeviceStatus.CHARGING_CANCELLED]: false,
  [DeviceStatus.SMART_CHARGING_COMPLETED]: false,
  [DeviceStatus.WAITING_FOR_SCHEDULED_CHARGING]: false,
  [DeviceStatus.CALCULATING_SCHEDULE]: true,
  [DeviceStatus.SMART_CHARGING_DISABLED]: false,
  [DeviceStatus.INITIALIZATION]: false,
  [DeviceStatus.NOT_REGISTERED]: false,
  [DeviceStatus.WAITING_FOR_CONNECTOR_STATE]: false,
  [DeviceStatus.OFFLINE]: false,
  [DeviceStatus.UNABLE_TO_CHARGE]: false,
};

export const statusToScheduleAvailable: Record<DeviceStatus, boolean> = {
  [DeviceStatus.ERROR]: false,
  [DeviceStatus.UNKNOWN]: false,
  [DeviceStatus.CABLE_PLUGGED]: false,
  [DeviceStatus.SMART_CHARGING]: true,
  [DeviceStatus.IMMEDIATE_CHARGING]: false,
  [DeviceStatus.CABLE_UNPLUGGED]: false,
  [DeviceStatus.CHARGING_CANCELLED]: false,
  [DeviceStatus.SMART_CHARGING_COMPLETED]: true,
  [DeviceStatus.WAITING_FOR_SCHEDULED_CHARGING]: true,
  [DeviceStatus.CALCULATING_SCHEDULE]: false,
  [DeviceStatus.SMART_CHARGING_DISABLED]: false,
  [DeviceStatus.INITIALIZATION]: false,
  [DeviceStatus.NOT_REGISTERED]: false,
  [DeviceStatus.WAITING_FOR_CONNECTOR_STATE]: false,
  [DeviceStatus.OFFLINE]: false,
  [DeviceStatus.UNABLE_TO_CHARGE]: false,
};

export const defaultProgressbarColor: Partial<Record<ProgressLinearBarType, string>> = {
  [ProgressLinearBarType.VALUE]: colors.pidgeonGray,
};

export const DEVICE_STATUS_REFRESH_MILLISECONDS = 30 * 1000;

export const statusToChargerOnline: Record<DeviceStatus, boolean> = {
  [DeviceStatus.ERROR]: false,
  [DeviceStatus.UNKNOWN]: false,
  [DeviceStatus.CABLE_PLUGGED]: true,
  [DeviceStatus.SMART_CHARGING]: true,
  [DeviceStatus.IMMEDIATE_CHARGING]: true,
  [DeviceStatus.CABLE_UNPLUGGED]: true,
  [DeviceStatus.CHARGING_CANCELLED]: true,
  [DeviceStatus.SMART_CHARGING_COMPLETED]: true,
  [DeviceStatus.WAITING_FOR_SCHEDULED_CHARGING]: true,
  [DeviceStatus.CALCULATING_SCHEDULE]: true,
  [DeviceStatus.SMART_CHARGING_DISABLED]: true,
  [DeviceStatus.INITIALIZATION]: false,
  [DeviceStatus.NOT_REGISTERED]: false,
  [DeviceStatus.WAITING_FOR_CONNECTOR_STATE]: false,
  [DeviceStatus.OFFLINE]: false,
  [DeviceStatus.UNABLE_TO_CHARGE]: true,
};

export const statusToVehicleOnline: Record<DeviceStatus, boolean> = {
  [DeviceStatus.ERROR]: false,
  [DeviceStatus.UNKNOWN]: false,
  [DeviceStatus.CABLE_PLUGGED]: true,
  [DeviceStatus.SMART_CHARGING]: true,
  [DeviceStatus.IMMEDIATE_CHARGING]: true,
  [DeviceStatus.CABLE_UNPLUGGED]: false,
  [DeviceStatus.CHARGING_CANCELLED]: true,
  [DeviceStatus.SMART_CHARGING_COMPLETED]: true,
  [DeviceStatus.WAITING_FOR_SCHEDULED_CHARGING]: true,
  [DeviceStatus.CALCULATING_SCHEDULE]: true,
  [DeviceStatus.SMART_CHARGING_DISABLED]: true,
  [DeviceStatus.INITIALIZATION]: false,
  [DeviceStatus.NOT_REGISTERED]: false,
  [DeviceStatus.WAITING_FOR_CONNECTOR_STATE]: false,
  [DeviceStatus.OFFLINE]: false,
  [DeviceStatus.UNABLE_TO_CHARGE]: true,
};
